import { RouteRecordRaw } from 'vue-router'
import { ProxyRouter } from './proxy'

// meta 中 user的tab表示需要等陆
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./../views/HomeV1/index.vue')
  },
  {
    path: '/ai-ppt',
    name: 'AiPpt',
    component: () => import('./../views/index.vue')
  },
  {
    path: '/loading',
    name: 'Loading',
    component: () => import('./../views/Loading/index.vue')
  }
]

const router = new ProxyRouter(routes).router

export default router
