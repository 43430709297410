<template>
  <KeepAlive>
    <router-view />
  </KeepAlive>
</template>

<script lang="ts" setup>
</script>

<style lang="scss"></style>
