import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  createWebHashHistory,
  RouteRecordRaw
} from 'vue-router'
// const HomeView = () => h('div', 'HomePage')

/**
 * 路由守卫
 */
export class ProxyRouter {
  router: Router

  constructor(routes: RouteRecordRaw[]) {
    //   初始化路由
    this.router = createRouter({
      history: createWebHashHistory(),
      routes
    })
    this.router.onError(error => {
      // alert(error)
      if (
        error?.message.includes('Failed to fetch dynamically imported module')
      ) { /* empty */ }
    })
    // 塞入页面拦截
    this.router.beforeEach(this.beforeEach.bind(this))
  }

  /**
   * 页面跳转前的拦截
   */
  // eslint-disable-next-line require-await
  async beforeEach(
    to: RouteLocationNormalized,
    form: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const { router } = this
    next()
  }
}
