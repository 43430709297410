import { DirectiveBinding } from 'vue'

const ShiftEnterDirective = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        el.addEventListener('keydown', function (event: KeyboardEvent) {
            console.log(event)
            if (event.shiftKey && event.keyCode === 13) {
                event.preventDefault();
                binding.value();
            }
        });
    },
};

export default ShiftEnterDirective;
